.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  justify-content: center;
  align-items: center; /* Align items to the top */
  padding: 2rem;
  /* background-color: #ffffff;
  color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1),
  0 0 30px rgba(0, 0, 0, 0.05),
  inset 0 0 10px rgba(0, 0, 0, 0.05); */
}

.home-banner {
  position: sticky;
  display: flex;
  align-items: left;
  width: 300px;
  height: auto;
  z-index: 1000;
}

.user-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: min(90%,600px);
}

.user-info span {
  /* margin-right: 10px; */
  font-size: 2.5rem;
  color: rgb(0, 0, 0);
}

.user-info button {
  border: none;
  border-radius: 15px;
  cursor: pointer;
  background-color: #007bff;
  color: rgb(255, 255, 255);
  font-size: 2.5rem;
  font-family: "Times New Roman", Times, serif;
  padding: 1rem;
  margin: 1rem;
}

.content-body {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  justify-content: center;
}

.receiver-endpoint {
  margin: 20px 0;
}

.receiver-endpoint p {
  margin: 0;
  font-weight: bold;
}

.receiver-endpoint a {
  color: #007bff;
  text-decoration: none;
  word-break: break-all;
}

.instructions {
  max-width: min(95%, 600px);
}

.usage-instructions {
  text-align: left;
  background-color: #ffffff;
  padding: 2rem;
  margin: 1rem;
  margin-top: 2.7rem;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), /* Outer shadow */
  0 0 30px rgba(0, 0, 0, 0.05), /* Outer shadow */
  inset 0 0 10px rgba(0, 0, 0, 0.05); /* Inner shadow */
}

.usage-instructions pre {
  font-family: monospace;
  background-color: #f5f5f5;
  color: #000;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.endpoint-url {
  background-color: #222828;
  color: #f8f8f2;
  padding: 10px;
  border-radius: 4px;
  text-wrap: wrap;
  word-break: break-all;
}

.logs-section {
  max-width: min(90%, 600px);
  width: 600px;
}

.logs-title {
  padding-left: 3rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  color: #7e7e7e;
  text-align: left;
}

.log-list {
  margin: 0 auto;
  padding: 2rem;
  background-color: #f4f4f400;
  border-radius: 8px;
  text-align: left; /* Align text to the left */
}

.log-item {
  max-width: min(95%, 600px);
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), /* Outer shadow */
  0 0 30px rgba(0, 0, 0, 0.05), /* Outer shadow */
  inset 0 0 10px rgba(0, 0, 0, 0.05); /* Inner shadow */
}

.log-details pre {
  white-space: pre-wrap; /* Ensure text wraps within pre tags */
  word-wrap: break-word; /* Break words to wrap long text */
}

.pagination {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #ffffffd3;
  padding: 10px 0;
  box-shadow: 0 0 20px rgb(255, 255, 255), /* Outer shadow */
  0 0 30px rgb(255, 255, 255), /* Outer shadow */
  inset 0 0 10px rgb(255, 255, 255); /* Inner shadow */
  z-index: 1000;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.page-input {
  display: inline-block;
  margin-left: 10px;
}

.page-input input {
  width: 50px;
  padding: 5px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.loading-circle {
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.scroll-to-logs {
  position: fixed;
  top: 50%;
  left: calc(100% - 110px); /* Adjust as necessary */
  transform: translateY(-200%);
  transition: top 0.3s ease-out;
}

.scroll-to-logs button {
  padding: 10px 20px;
  background-color: #007bff00;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.call-to-login {
  position: sticky;
  top: 50%;
  left: calc(100% - 110px); /* Adjust as necessary */
  transform: translateY(-1500%);
  transition: top 0.3s ease-out;
}

.call-to-login button {
  padding: 10px 20px;
  background-color: #007bff00;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}